// @ts-nocheck
import { useContext, useEffect, useRef, useState } from "react";
import { StoreContext } from "index";
import Onboarding from "@metamask/onboarding";
import MetaMaskOnboarding from "@metamask/onboarding";
import { Link } from "react-router-dom";
import Logo from "assets/images/logos/avo-full-logo.png";
import AppMenuIcon from "assets/images/icons/navbar/hamburger.svg";
import CloseIcon from "assets/images/icons/navbar/close.svg";
import { AccountDisplay, WalletModal, FullSidebar } from "components";
import { useHistory } from "react-router-dom";
import { HeaderDropdownMenu } from "./HeaderDropdownMenu";
import { SocialMenu } from "./SocialMenu";
import TwitterIcon from "assets/images/icons/twitter.svg";
import DiscordIcon from "assets/images/icons/discord.svg";
import MediumIcon from "assets/images/icons/medium.svg";
import TelegramIcon from "assets/images/icons/telegram.svg";
import UniswapIcon from "assets/images/icons/uniswap.svg";
// import BitmartIcon from 'assets/images/icons/bitmart.svg';
import MEXCIcon from "assets/images/icons/mexc.svg";
import NewsIcon from "assets/images/icons/news.svg";
import PancakeSwapIcon from "assets/images/icons/icon-cake-1.svg";
import Header from "./Bridge/Header";
import { useLocation } from "react-router-dom";
import { useWeb3Context } from "../providers/Web3ContextProvider";
import FebNews2023 from "assets/images/news-page/feb-23.jpg";
import JanNews2023 from "assets/images/news-page/jan-23.jpg";
import DecNews2022 from "assets/images/news-page/dec-22.jpg";
import NovNews2022 from "assets/images/news-page/nov-22.jpg";
import OctNews from "assets/images/news-page/oct-22.jpg";
import SepNews from "assets/images/news-page/sep-22.jpg";
import AugNews from "assets/images/news-page/aug-22.jpg";
import JulNews from "assets/images/news-page/jul-22.jpg";
import JunNews from "assets/images/news-page/jun-22.jpg";
import MayNews from "assets/images/news-page/may-22.jpg";
import AprNews from "assets/images/news-page/apr-22.jpg";
import MarNews from "assets/images/news-page/mar-22.jpg";
import FebNews from "assets/images/news-page/feb-22.jpg";
import JanNews from "assets/images/news-page/jan-22.jpg";
import DecNews from "assets/images/news-page/dec-21.jpg";
import NovNews from "assets/images/news-page/nov-21.jpg";

type HeaderProps = {
  onPressMenu?: () => void;
  isNavbarOpen?: boolean;
  connectedAccount?: string;
  connectWalletCallback?: () => void;
};

const linkToWhitePaper = "https://whitepaper.avocadodao.io/";
const linkToAvodude = "https://quests.avocadodao.io/avocadude/"

function AppHeader({
  onPressMenu = () => { },
  isNavbarOpen = false,
  connectedAccount = "",
  connectWalletCallback = () => { },
}: HeaderProps) {
  // const TABLET_WIDTH = 768;
  const TABLET_WIDTH = 1024;
  const store = useContext(StoreContext);
  const history = useHistory();
  const [isOpen, setIsOpen] = useState(false);
  const { innerWidth: width } = window;
  const onboarding = useRef<Onboarding>();
  const location = useLocation();
  const { address, signer } = useWeb3Context();

  useEffect(() => {
    if (!onboarding.current) {
      onboarding.current = new MetaMaskOnboarding();
    }
  }, []);

  function openModal() {
    setIsOpen(true);
  }

  function onAfterOpen() {
    // references are now sync'd and can be accessed.
  }

  function closeModal() {
    setIsOpen(false);
  }

  // If account not connected, show landing page app header
  return (
    <>
      <FullSidebar isOpen={isNavbarOpen} onPressConnect={openModal} />
      <header className="sticky-main-header">
        <Link to="/">
          <img
            src={Logo}
            alt="AVG_LOGO"
            width={width <= 480 ? 94 : 165}
            height={"auto"}
          />
        </Link>
        <div className="flex flex-row align-center header-content">
          <div className="flex flex-row full-header-content">
            {/* <a
              href={linkToAvodude}
              target="_blank"
              rel="noreferrer"
              style={{ textDecoration: "none" }}
            >
              <button className="btn-text">AVOLUTION</button>
            </a> */}
            {/* <HeaderDropdownMenu title="NEWS" items={headerItems.news} />
            <a
              href={linkToWhitePaper}
              target="_blank"
              rel="noreferrer"
              style={{ textDecoration: "none" }}
            >
              <button className="btn-text">WHITEPAPER</button>
            </a>
            <div className="staking">
              <a href="https://core.avocadodao.io/games/" target="_blank">
                <button className="btn-text link">GAMES</button>
              </a>
            </div>
            <div className="staking">
              <Link to="/bridge">
                <button className="btn-text">BRIDGE</button>
              </Link>
            </div>
            <div className="staking">
              <a href="https://staking.avocadodao.io" target="_blank">
                <button className="btn-text link">STAKING</button>
              </a>
            </div>
            <div className="staking">
              <a href="https://rewards.avocadodao.io" target="_blank">
                <button className="btn-text link">VESTING</button>
              </a>
            </div>
            <SocialMenu title="SOCIAL" items={headerItems.social} />
            <HeaderDropdownMenu title="BUY AVG" items={headerItems.token} />
          </div>
          {location.pathname === "/bridge" && signer ? <Header /> : ""}
          <div className="mobile-header-content" style={{ marginLeft: "15px" }}>
            <button onClick={onPressMenu} className="toggle-navbar-btn">
              <img
                src={isNavbarOpen ? CloseIcon : AppMenuIcon}
                alt={isNavbarOpen ? "Close menu" : "Open menu"}
                style={{ marginRight: isNavbarOpen ? 3 : 0 }}
              />
            </button> */}
          </div>
          {!signer ? (
            <>
              <button
                className="btn-hero btn-connect btn-header"
                onClick={openModal}
              >
                CONNECT WALLET
              </button>
              <a href="https://quests.avocadodao.io/join" target="_blank">
                <button className="btn-hero btn-connect btn-header">
                  JOIN
                </button>
              </a>
            </>
          ) : (
            <AccountDisplay connectedAccount={address} />
          )}

          {/* {signer && (
            <div>
              <div
                className={totalActionNum || totalPaddingNum ? classes.activeChainLocale : classes.chainLocale}
                onClick={() => {
                  handleOpenHistoryModal();
                }}
              >
                <div className={classes.historyText}>{getstatusText()}</div>
              </div>
            </div>
          )} */}
        </div>
        <WalletModal
          isOpen={isOpen}
          onAfterOpen={onAfterOpen}
          closeModal={closeModal}
          width={width}
        />
      </header>
    </>
  );

  // Otherwise, show connected account app header
  // return (
  //   <header
  //     className={`align-center main-header${
  //       width > TABLET_WIDTH ? ' justify-end' : ' justify-space-between'
  //     }`}
  //   >
  //     {width <= TABLET_WIDTH ? (
  //       <Link to='/'>
  //         <img
  //           src={Logo}
  //           alt='AVG_LOGO'
  //           width={width <= 480 ? 98 : 208}
  //           height={width <= 480 ? 35 : 75}
  //         />
  //       </Link>
  //     ) : null}
  //     {width > TABLET_WIDTH ? (
  //       <div className='flex flex-row align-center'>
  //         {/* <button className="btn-text header-white-paper">Whitepaper</button> */}

  //         <AccountDisplay connectedAccount={store?.appStore.getAccounts()[0]} />
  //       </div>
  //     ) : null}
  //     {width <= TABLET_WIDTH ? (
  //       <button onClick={onPressMenu} className='toggle-navbar-btn'>
  //         <img
  //           src={isNavbarOpen ? CloseIcon : AppMenuIcon}
  //           alt={isNavbarOpen ? 'Close menu' : 'Open menu'}
  //           style={{ marginRight: isNavbarOpen ? 3 : 0 }}
  //         />
  //       </button>
  //     ) : null}
  //   </header>
  // );
}

const headerItems = {
  social: [
    {
      title: "Medium",
      icon: MediumIcon,
      href: "https://avocadoguild.medium.com/",
    },
    {
      title: "Discord",
      icon: DiscordIcon,
      href: "https://discord.gg/avocadoguild",
    },
    {
      title: "Twitter",
      icon: TwitterIcon,
      href: "https://twitter.com/avocadoguild",
    },
    {
      title: "Telegram",
      icon: TelegramIcon,
      href: "https://t.me/avocado_guild",
    },
  ],
  token: [
    {
      title: "PancakeSwap",
      icon: PancakeSwapIcon,
      href: "https://pancakeswap.finance/swap?outputCurrency=0xa41f142b6eb2b164f8164cae0716892ce02f311f",
      menu: [
        {
          title: "Buy AVG",
          href: "https://pancakeswap.finance/swap?outputCurrency=0xa41f142b6eb2b164f8164cae0716892ce02f311f",
        },
        {
          title: "Add Liquidity",
          href: "https://pancakeswap.finance/add/0xa41F142b6eb2b164f8164CAE0716892Ce02f311f/BNB",
        },
      ],
    },
    {
      title: "UniSwap",
      icon: UniswapIcon,
      href: "https://app.uniswap.org/#/swap?inputCurrency=0xa41f142b6eb2b164f8164cae0716892ce02f311f&outputCurrency=ETH",
    },
    {
      title: "MEXC",
      icon: MEXCIcon,
      href: "https://www.mexc.com/exchange/AVG_USDT",
    },
    // {
    //   title: 'Bitmart',
    //   icon: BitmartIcon,
    //   href: 'https://www.bitmart.com/trade/en?symbol=AVG_USDT&layout=basic',
    // },
  ],
  news: [
    {
      title: "Newsletters",
      icon: NewsIcon,
      href: "https://www.avocadodao.io/news",
    },
    {
      title: "Blogs",
      icon: NewsIcon,
      href: "https://core.avocadodao.io/blog/",
    },
  ],
  newsletter: [
    {
      title: "Feburary 23",
      icon: NewsIcon,
      href: "https://heyzine.com/flip-book/db9a466d31.html",
      src: FebNews2023,
    },
    {
      title: "January 23",
      icon: NewsIcon,
      href: "https://heyzine.com/flip-book/4646b51b93.html",
      src: JanNews2023,
    },
    {
      title: "December 22",
      icon: NewsIcon,
      href: "https://heyzine.com/flip-book/ab4af34d15.html",
      src: DecNews2022,
    },
    {
      title: "NOVEMBER 22",
      icon: NewsIcon,
      href: "https://heyzine.com/flip-book/bcee254882.html",
      src: NovNews2022,
    },
    {
      title: "OCTOBER 22",
      icon: NewsIcon,
      href: "https://heyzine.com/flip-book/f1f03f941e.html",
      src: OctNews,
    },
    {
      title: "SEPTEMBER 22",
      icon: NewsIcon,
      href: "https://heyzine.com/flip-book/1cc2f2df42.html",
      src: SepNews,
    },
    {
      title: "AUGUST 22",
      icon: NewsIcon,
      href: "https://heyzine.com/flip-book/d2ae73d1fd.html",
      src: AugNews,
    },
    {
      title: "JULY 22",
      icon: NewsIcon,
      href: "https://heyzine.com/flip-book/82798a580e.html",
      src: JulNews,
    },
    {
      title: "JUNE 22",
      icon: NewsIcon,
      href: "https://heyzine.com/flip-book/746a31dde0.html",
      src: JunNews,
    },
    {
      title: "MAY 22",
      icon: NewsIcon,
      href: "https://heyzine.com/flip-book/d72272fcbb.html",
      src: MayNews,
    },
    {
      title: "APRIL 22",
      icon: NewsIcon,
      href: "https://heyzine.com/flip-book/ab489828ff.html",
      src: AprNews,
    },
    {
      title: "MARCH 22",
      icon: NewsIcon,
      href: "https://heyzine.com/flip-book/579f684416.html",
      src: MarNews,
    },
    {
      title: "FEBRUARY 22",
      icon: NewsIcon,
      href: "https://heyzine.com/flip-book/26855673d7.html",
      src: FebNews,
    },
    {
      title: "JANUARY 22",
      icon: NewsIcon,
      href: "https://heyzine.com/flip-book/cc1e395774.html",
      src: JanNews,
    },
    {
      title: "DECEMBER 21",
      icon: NewsIcon,
      href: "https://heyzine.com/flip-book/9cbc873b6d.html#page/1",
      src: DecNews,
    },
    {
      title: "NOVEMBER 21",
      icon: NewsIcon,
      href: "https://heyzine.com/flip-book/b0c1d4b973.html",
      src: NovNews,
    },
  ],
};

export { AppHeader, headerItems };
