import { useState } from "react";
import { observer } from "mobx-react-lite";
import "./News.scss";
import "../Games/MainGamesNews.scss";
import { NewsItem } from '../../../components/NewsItem';
import { headerItems } from 'components';

type Props = {};

export const News = observer(({ }: Props) => {
  const [news, setNews] = useState(headerItems.newsletter)
  return (
    <div className="page-news">
      <div className="page-news__container">
        <div className="flex flex-wrap justify-center ">
          {
            news.map((item) => (
              <NewsItem item={item} />
            ))
          }
        </div>
      </div>
    </div>
  )
})