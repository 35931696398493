type NewsItemProps = {
  title: string;
  href: string;
  icon?: any;
  src: any;
}

type Props = {
  item: NewsItemProps
}

function NewsItem({ item }: Props) {
  return (
    <a 
      href={item.href}
      target='_blank'
      rel='noreferrer noopener'
      className="news-item__container"
    >
      <div className="news-item__title">
        {item.title}
      </div>
      <img src={item.src} alt={item.title} className="news-item__image"/>
    </a>
  )
}

export { NewsItem };
export type { NewsItemProps };