import React, { useRef, useEffect } from "react";
import { useLocation } from "react-router-dom"
import ArrowRightIcon from "../assets/images/icons/arrow-right.svg";

import FooterLandscape from "assets/images/hero-bg-waves.svg";
import FooterLandscapeMobile from "assets/images/astro-bg-mobile.png";
import AstroManIcon from "assets/images/icons/astro-man.png";
import AvoPlanet from "assets/images/avo-planet.png";
import { FooterSection } from "components";

type Props = {
  inputValue?: string;
  onChangeValue?: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

function TransmissionsSection({
  onChangeValue = () => { },
  inputValue = "",
}: Props) {
  const location = useLocation()
  const divEl = useRef<any>(null)
  const { innerWidth: width } = window;
  const MOBILE_WIDTH = 480;
  const TABLET_WIDTH = 768;




  return (
    <div ref={divEl} className="flex flex-column align-center justify-center transmissions-section">
      {width > MOBILE_WIDTH ? (
        <img
          src={FooterLandscape}
          alt="Footer background"
          style={{
            position: "absolute",
            left: 0,
            minWidth: "100vw",
            objectFit: "contain",
            zIndex: 5,
          }}
          className='footer-background'
        />
      ) : null}
      {width > MOBILE_WIDTH ? (
        <img
          src={AstroManIcon}
          alt="Astro Man"
          className="astro-man"
          style={{ height: 200 }}
        />
      ) : null}
      {width <= MOBILE_WIDTH ? (
        <img
          src={FooterLandscapeMobile}
          alt="Footer background"
          style={{
            position: "absolute",
            left: 0,
            width: "100%",
            objectFit: "contain",
            zIndex: 5,
          }}
          className="footer-landscape-mobile"
        />
      ) : null}
      <img
        src={AvoPlanet}
        alt="Avo planet"
        style={{
          height: width > TABLET_WIDTH ? "40%" : "19%",
        }}
        className="avo-planet"
      />
      <div className="flex justify-space-between transmissions-box align-center">
        <div className="flex flex-column receive-transmissions">
          <div className="title box-title">Receive Transmissions</div>
          <p className="body">
            Join {width <= MOBILE_WIDTH ? " AG's " : "Avocado DAO's"} mailing
            list to get the latest news.
          </p>
        </div>

        <form
          action="https://www.aweber.com/scripts/addlead.pl"
          method="POST"
          noValidate
          target="_blank"
          className="flex flex-row align-center enter-email"
        >
          <input type="hidden" name="meta_web_form_id" value="963183686" />
          <input type="hidden" name="meta_split_id" value="" />
          <input type="hidden" name="listname" value="awlist6272710" />
          <input type="hidden" name="redirect" value="https://www.aweber.com/form/thankyou_vo.html" id="redirect_e515cce0409b1271d209cf9655eca962" />
          <input type="hidden" name="meta_adtracking" value="My_Web_Form" />
          <input type="hidden" name="meta_message" value="1" />
          <input type="hidden" name="meta_required" value="email" />
          <input type="hidden" name="meta_tooltip" value="" />

          {/* <input
            type="hidden"
            name="u"
            value="c3f14be3cca6dce34434df0c5"
            readOnly
          />
          <input type="hidden" name="id" value="e9e93c46bb" readOnly /> */}
          <label htmlFor="MERGE0" style={{ display: "none" }}>
            Email
          </label>
          <input
            className="email-input body"
            type="email"
            name="email"
            id="MERGE0"
            placeholder="Your Email"
            value={inputValue}
            onChange={onChangeValue}
            autoCapitalize="off"
            autoCorrect="off"
          />

          <button
            type="submit"
            name="subscribe"
            id="mc-embedded-subscribe"
            className="flex justify-center align-center submit-button"
          >
            <img src={ArrowRightIcon} alt="Submit email" height={28} />
          </button>

          <div
            style={{ position: "absolute", left: "-5000px" }}
            aria-hidden="true"
            aria-label="Please leave the following three fields empty"
          >
            <label htmlFor="b_email">Email: </label>
            <input
              type="email"
              name="b_email"
              tabIndex={-1}
              value=""
              placeholder="youremail@gmail.com"
              id="b_email"
              readOnly
            />
          </div>
        </form>
      </div>
      <div className="flex community-section">
        <div className="community-text">
          <div className="title-lg community-cta">Join Us Now</div>
          <a
            href="https://discord.gg/XQQRkFTCXS"
            target="_blank"
            rel="noreferrer noopener nofollow"
            style={{ textDecoration: "none" }}
          >
            <div className="title-sm community-link">Go to the Avocado Community Site</div>
          </a>
        </div>
      </div>

      <FooterSection />
    </div>
  );
}

export { TransmissionsSection };
