import React, { useEffect } from "react";
import { PartnersDisplay } from "./PartnersDisplay";

import AnimocaBrands from "assets/images/partners/AnimocaBrands-logo.png";
import HASHED from "assets/images/partners/Hashed-logo.png";
import QCPSoteria from "assets/images/partners/QSNSoteria-logo.png";
import BinanceSmartChain from "assets/images/partners/BinanceSmartChain-logo.png";
import Polygon from "assets/images/partners/Polygon-logo.png";
import Solana from "assets/images/partners/Solana-logo.png";
import Algorand from "assets/images/partners/Algorand-logo.png";
import LDCapital from "assets/images/partners/LDCapital-logo.svg";
import MEXCGlobal from "assets/images/partners/MEXC-logo.svg";
import KeneticHoldings from "assets/images/partners/KeneticHoldings-logo.svg";
import PNYX from "assets/images/partners/PNYX-logo.png";

export type PartnerType = {
  name: string;
  logo: any;
};

const dummyPartners: PartnerType[] = [
  { name: "Animoca Brands", logo: AnimocaBrands },
  { name: "QCP Soteria", logo: QCPSoteria },
  { name: "HASHED", logo: HASHED },
  { name: "Solana", logo: Solana },
  { name: "Binance Smart Chain", logo: BinanceSmartChain },
  { name: "Polygon", logo: Polygon },
  { name: "Algorand", logo: Algorand },
  { name: "PNYX", logo: PNYX },
  { name: "MEXC Global", logo: MEXCGlobal },
  { name: "LD Capital", logo: LDCapital },
  { name: "Kenetic Holdings", logo: KeneticHoldings },
];

function PartnersSection() {
  useEffect(() => {
    const scrollToHash = () => {
      const { hash } = window.location;

      const elementToScroll = document.getElementById(hash.replace("#", ""));

      if (!elementToScroll) return;

      const viewportOffset = elementToScroll.getBoundingClientRect();

      window.scrollTo({
        top: viewportOffset.top,
        behavior: "smooth",
      });
    };

    const timer = setTimeout(() => {
      if (document.readyState === "complete") {
        scrollToHash();
      } else {
        window.addEventListener("load", scrollToHash);

        return () => window.removeEventListener("load", scrollToHash);
      }
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div id="email" className="partners-section flex flex-column align-center">
      <div className="title-xl partners-heading justify-center">
        Our Partners
      </div>
      <div className="text-wrap">
        <p className="body">Investors and Backers</p>
      </div>
      <PartnersDisplay partners={dummyPartners} />
    </div>
  );
}

export { PartnersSection };
