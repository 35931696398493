import React from "react";
import './PrivacyPolicy.scss'

export const PrivacyPolicy = () => {
    return (<div className="page-pp">
        <div className="page-pp__container">
            <div className="flex flex-wrap justify-center ">
                <h1>Privacy Policy</h1>
            </div>
            <div>
                <ol className="ol-heading">
                    <li>
                        Compliance with British Virgin Islands Principles
                        <ol className="ol-paragraph">
                            <li>Avocado IDO Limited (AG) respects and acknowledges the importance of privacy. AG complies with the Data Protection Act, 2019 (DPA), when collecting, using, disclosing and managing your personal information.</li>
                            <li>This Privacy Policy contains information in relation to our management of your personal information in accordance with the DPA.</li>
                            <li>For the purposes of this Privacy Policy, the term “personal information” has the meaning given to it in the Act.</li>
                        </ol>
                    </li>
                    <li>
                        Use of personal information
                        <ol className="ol-paragraph">
                            <li>
                                AG may collect and use your personal information for a variety of reasons, which may include but is not limited to the following:
                                <ol>
                                    <li>to process your request in relation to any customisation to any service provided by AG;</li>
                                    <li>to provide you with goods and services you request from AG;</li>
                                    <li>to provide you with requested information on products of services provided by AG;</li>
                                    <li>to add you to the AG mailing list;</li>
                                    <li>to process payments, whether by way of currency, cryptocurrency or other forms of non-fungible tokens;</li>
                                    <li>to notify you of new game releases or crypto currency updates;</li>
                                    <li>to detect security issues and prevent fraud on the AG website and/or platform;</li>
                                    <li>to request feedback on your experience with AG;</li>
                                    <li>to provide you with support in relation to the AG website and/or platform;</li>
                                    <li>to enable you to access the AG website and/or platform;</li>
                                    <li>to complete your requested transactions, to verify the existence of and any conditions attached to your accounts or to assist with a transaction;</li>
                                    <li>to process transactions and send notifications about your transactions;</li>
                                    <li>confirming your identity for the purposes of anti-money laundering and counter-terrorism laws;</li>
                                    <li>to manage and minimise risks and identify or investigate fraud and/or other illegal activities;</li>
                                    <li>responding to your enquiries which include the processing of complaints made to AG;</li>
                                    <li>the resolution of disputes, collection of fees and troubleshooting problems;</li>
                                    <li>enforcement of our terms and conditions;</li>
                                    <li>deliver targeted marketing and various other notices and promotional communications based on your selected communication preferences;</li>
                                    <li>facilitate contests, sweepstakes, and promotions and process and deliver entries and rewards in relation to same;</li>
                                    <li>in the performance of data analytics to improve our website and platform, and your experience with AG;</li>
                                    <li>to analyse trends in your visits to the AG website;</li>
                                    <li>to gather demographic information based on the visits to the AG website;</li>
                                    <li>protecting AG and its assets (including against fraud) and selling AG assets and/or services (including any assignment of debts);</li>
                                    <li>enforcing AG’s rights (including undertaking debt collection) in connection with the AG website and/or platform;</li>
                                    <li>monitoring data, usage, records and statistics derived from your use of the website for the furtherance of AG’s business, for general market research and to be disclosed to third parties in relation to the aforementioned;</li>
                                    <li>to contact you through the use of various means e.g. telephone, text (SMS) or email messaging, advertising through our website, our platform, third-party websites, mail, and/or any other means as authorised by our terms and conditions;</li>
                                    <li>to provide to government or law enforcement officials to protect AG’s rights and property as well as those of third party and protect the safety of the public or any person</li>
                                    <li>AG’s business development, including sending of updates and publications;</li>
                                    <li>to manage and deliver contextual and behavioural advertising;</li>
                                    <li>to improve and personalise our website and to learn about your level of satisfaction to ensure client satisfaction throughout the future of the website;</li>
                                    <li>auditing and managing the use of AG’s website; or</li>
                                    <li>in order for AG to comply with legal and regulatory obligations.</li>
                                </ol>
                            </li>
                            <li>You may withdraw your consent to receive marketing materials from AG by contacting our Privacy Officer, details of which are contained within this Privacy Policy.</li>
                        </ol>
                    </li>
                    <li>
                        Collection of personal information
                        <ol className="ol-paragraph">
                            <li>
                                AG collects personal information through a variety of methods, which may include the following:
                                <ol>
                                    <li>directly from you;</li>
                                    <li>through your access and use of AG’s website and platforms;</li>
                                    <li>through your access and use of AG’s software, applications or other platforms commissioned by AG for the provision of AG’s services;</li>
                                    <li>through your access of surveys commissioned by AG;</li>
                                    <li>when you subscribe to receive information from AG;</li>
                                    <li>when you communicate with AG, by email, post or otherwise;</li>
                                    <li>through publicly available information;</li>
                                    <li>financing providers with which AG offer financing for purchases, and financial service providers used when processing your payments; or</li>
                                    <li>through any social media account(s) which you use to create or log into AG’s website.</li>
                                    <li>through user-generated content you submit in AG’s forums, website or social media platforms</li>
                                </ol>
                            </li>
                            <li>In the event that AG is unable to obtain personal information from you as outlined above, this may result in AG being unable to provide you with access to website (including any updates) or marketing material.</li>
                        </ol>
                    </li>
                    <li>
                        Types of personal information and storage
                        <ol className="ol-paragraph">
                            <li>
                                The type of personal information that AG may hold includes the following:
                                <ol>
                                    <li>identification information including your name, identification number, date of birth, gender, phone number, address, email address, and other contact information;</li>
                                    <li>demographic and statistical information including but not limited to gender, interests, lifestyle information, and hobbies</li>
                                    <li>identification photos and visual images with personal appearances</li>
                                    <li>user-generated content including but not limited to photos, videos, audio, information about your content and any information you provide</li>
                                    <li>details of any payment methods or wallets which you may provide for the purposes of processing payments, whether by way of currency, cryptocurrency or other forms of non-fungible tokens;</li>
                                    <li>data necessary to process your payment if you are making any purchase through AG;</li>
                                    <li>registration information including an email address, username and password;</li>
                                    <li>your approximate physical location of the devices you use to access our website;</li>
                                    <li>your IP address and other device identifiers, including mobile advertising identifiers, browser type and language, operating system, platform type, device type, software and hardware attributes</li>
                                    <li>your payment details;</li>
                                    <li>your account history, including any metadata contained in any content submitted by you via the AG website and/or platform, or that you have enquired about;</li>
                                    <li>aggregate, anonymous, or de-identified non-personal data;</li>
                                    <li>social media information, if you access our services through a third-party connection or log-in, we may have access to information you provide to that social network such as your name, email address, friend list, photo, gender, location, and current city; and information you provide to us directly through our pages on social networking and blogging platforms (e.g,, Facebook, Instagram, Discord, Tiktok, Twitter, Telegram, Twitch, Google)</li>
                                    <li>any other information provided by you in using AG’s websites; and</li>
                                    <li>other information that helps us to identify you or helps us to provide or improve our website.</li>
                                </ol>
                            </li>
                            <li>AG may collect various financial information such as the full bank account details or credit card numbers that you link to your account with us</li>
                            <li>The personal information referred to in clause 4.1 above may be held by AG in both hardcopy files and also in an electronic form.</li>
                        </ol>
                    </li>
                    <li>
                        Disclosure of personal information
                        <ol className="ol-paragraph">
                            <li>AG may be required to disclose your personal information to the following third parties:
                                <ol>
                                    <li>external service providers so that the third party can carry out the service that they have been engaged by AG for;</li>
                                    <li>our partners and the suppliers and service providers who help with our business operations including in relation to fraud prevention, identity verification, payment collection, marketing, customer service, and technology services;</li>
                                    <li>to third party advertisers, or to AG to choose or allow advertisers to choose the appropriate audience for their advertisements.</li>
                                    <li>third party offer walls which allow advertisers to provide virtual money to AG users in return for advertisement work.</li>
                                    <li>third parties who have instructed us to provide goods and/or services;</li>
                                    <li>organisations that provide applications, websites, services, goods, software, programs used by AG;</li>
                                    <li>third parties that may provide AG with marketing or analytics reports;</li>
                                    <li>organisations that help identify illegal activities and prevent fraud;</li>
                                    <li>our professional advisors, including our accountants, lawyers, business advisors and consultants;</li>
                                    <li>organisations and/or individuals that AG intends on entering negotiations with for any merger, sale of assets, financing, acquisition of all or a part of AG’s business; or</li>
                                    <li>any legal industry regulatory body in any of the states, territories and jurisdictions that AG operates in.</li>
                                </ol>
                            </li>
                            <li>AG may be required to disclose your personal information in order to respond to subpoenas, court orders, or to investigate, prevent, defend against, or take action regarding violations of our terms and conditions, illegal activities, suspected fraud, or situations involving potential threats to the legal rights or physical safety of any person or the security of our network, customers/users or services.</li>
                            <li>Any payments made on the AG website and/or platform, will be processed through our third-party payment processor where they will collect the billing and financial information required to process your charges. This may include your name, address, e-mail address, and financial information. AG’s payment processors do not share your financial information with AG, but they may share non-financial information with us related to your purchases, including your name, address, and the service purchased.</li>
                            <li>Where the Act permits, AG may also disclose personal information to third party suppliers and service providers located overseas for some of the purposes listed above.</li>
                        </ol>
                    </li>
                    <li>
                        Cross-border disclosure of personal information
                        <ol className="ol-paragraph">
                            <li>AG may, from time to time, have affiliated offices operating in overseas. AG may send your personal information to these offices for one or more of the purposes listed in clause 2.1. If AG’s overseas offices are operated by ‘related body corporates’ of AG, AG will take such steps as a reasonably required to ensure that there is appropriate data handling of your personal information and proper security arrangements are in place</li>
                            <li>From time to time, AG may also send your personal information overseas for the following reasons:
                                <ol>
                                    <li>to third party service providers who store data or operate outside of BVI;</li>
                                    <li>to complete a transaction involving an international financial institution; or</li>
                                    <li>as required by laws and regulations of BVI or another country.</li>
                                </ol>
                            </li>
                            <li>Before AG discloses personal information about you to an overseas recipient who is not a related entity of you, AG will take such steps as reasonably required to ensure that there is appropriate data handling of your personal information and proper security arrangements are in place.</li>
                            <li>If you are a user of the AG website and located in the European Union, you may also have rights under the General Data Protection Regulation (GDPR). Further details of any additional rights you may have are outlined in Annexure A. </li>
                        </ol>
                    </li>
                    <li>
                        Cookies
                        <ol className="ol-paragraph">
                            <li>Cookies are used by AG in order to maximise and enhance our user experience.</li>
                            <li>When accessing AG’s website, small files of data may be placed on your device that enable AG to recognise you as a AG customer each time you return to our website. As a result of these cookies, you avoid the need to keep inputting your information throughout a session and may have these details auto filled when you visit the website.  In addition, these cookies enable AG to ascertain information regarding what web pages you visit and how regularly, enabling us to make our websites and platforms increasingly user friendly and to target advertising to content that you may be interested in.</li>
                            <li>You are free to decline the cookies in which AG utilises and can disable them through your web browser.</li>
                        </ol>
                    </li>
                    <li>
                        Protection of personal information
                        <ol className="ol-paragraph">
                            <li>AG will take all reasonable steps to ensure that your personal information is properly protected from misuse, loss, unauthorised access, modification or disclosure, and will maintain, monitor and enforce reasonable organisational, administrative, technical and physical safeguards to protect the security and confidentiality of your personal information.</li>
                        </ol>
                    </li>
                    <li>
                        Access to Privacy Policy
                        <ol className="ol-paragraph">
                            <li>This Privacy Policy is publicly available, free of charge through AG’s website.</li>
                        </ol>
                    </li>
                    <li>
                        Change to Privacy Policy
                        <ol className="ol-paragraph">
                            <li>This Privacy Policy may change from time to time as required by AG in order to reflect legislative and other changes.</li>
                        </ol>
                    </li>
                    <li>
                        Accessing and requesting correction of Personal Information
                        <ol className="ol-paragraph">
                            <li>To access, seek or request personal information that AG holds about you, please contact us at:
                                The Privacy Officer
                                privacy@avocadoguild.com</li>
                            <li>In the event that access to personal information is requested, AG will endeavour to respond to that request as soon as reasonably practicable if and to the extent required by the applicable law.</li>
                            <li>If AG is unable to give you access, or if AG declines to amend your personal information, AG will issue a written notice that describes our reasoning for doing so. AG notes that before providing you with any personal information AG will be required to verify your identity.</li>
                            <li>AG reserves the right to make an administrative charge to you if it is required, to provide you with access to personal information as outlined in clauses 11.1 and 11.2 above</li>
                        </ol>
                    </li>
                    <li>
                        Resolving your concerns
                        <ol className="ol-paragraph">
                            <li>If you wish to make a complaint regarding that way in which AG manages your personal information, or that you feel that AG has fallen short of the required standards set by the Act, please prepare your complaint in writing and supply it to The Privacy Officer.</li>
                            <li>A written acknowledgement of your complaint will be provided within 7 days, and AG will attempt to have your concern resolved within 30 days of receipt of your complaint.</li>
                        </ol>
                    </li>
                </ol>
            </div>
            <div className="flex flex-wrap justify-left ">
                <h1>Annexure A</h1>
            </div>
            <div>
                <p><strong>Additional rights for individuals located in the European Union</strong></p>
                <p>The GDPR has co-ordinated the laws in the European Union (EU) in respect of data privacy. The GDPR provides individuals with more rights and imposes further obligations such as requiring the organisation to provide more information on how it collects, uses, shares and stores personal information, to protect that individual’s personal information.</p>
            </div>
            <div>
                <ol className="ol-heading">
                    <li>
                        Interpretation
                        <ol className="ol-paragraph">
                            <p>In this Annexure A, any references to:</p>
                            <li>“personal information” refers to any information relating an identifiable natural person; and </li>
                            <li>“personal data” has the meaning given to it in the GDPR.</li>
                            <p>If you are located in the EU and have an enquiry relating to your rights under the GDPR, please contact the data protection officer, who is the Privacy Officer specified in clause 11 of our Privacy Policy.</p>
                        </ol>
                    </li>
                    <li>
                        Personal information we collect
                        <ol className="ol-paragraph">
                            <li>The personal information that will be collected from you is listed in clause 4 of our Privacy Policy.</li>
                            <li>There are specific types of personal information that we may only obtain or use with your consent or where it is lawfully permitted. Example of these types of personal information include, but is not limited to:
                                <ol>
                                    <li>your racial or ethnic origin;</li>
                                    <li>political opinion;</li>
                                    <li>religious or philosophical beliefs;</li>
                                    <li>trade union memberships;</li>
                                    <li>biometric data; or</li>
                                    <li>data relating to your health, sex life or sexual orientation.</li>
                                </ol>
                            </li>
                        </ol>
                    </li>
                    <li>
                        Duration personal information is stored
                        <ol className="ol-paragraph">
                            <li>Generally, your personal information may be retained by us for up to seven (7) years after your stop being a customer of AG.</li>
                            <li>We may however require your personal information for longer periods so that AG may:
                                <ol>
                                    <li>fulfil AG’s legal or regulatory obligations;</li>
                                    <li>conduct internal research and/or analytics; and</li>
                                    <li>respond to a enquiry or complaint submitted by you.</li>
                                </ol>
                            </li>
                        </ol>
                    </li>
                    <li>
                        Use of your personal information
                        <ol className="ol-paragraph">
                            <li>AG may only use your personal information if we have a lawful reason to do so. Such reasons may include:
                                <ol>
                                    <li>fulfilling contract or service you have requested from us;</li>
                                    <li>fulfilling an enquiry submitted by you;</li>
                                    <li>complying with any applicable law(s);</li>
                                    <li>you have provided AG with consent to use your personal information for a specific purpose; and</li>
                                    <li>subject to an overriding objective to protect your personal information, providing your personal information for the legitimate interests of AG and/or a third party</li>
                                </ol>
                            </li>
                        </ol>
                    </li>
                    <li>
                        Your rights
                        <ol className="ol-paragraph">
                            <li>The GDPR affords you with the following rights in respect of your personal information:
                                <ol>
                                    <li><strong>(notification)</strong> to be notified and informed on how your personal information is collected and used. You may withdraw your consent for AG to use your personal information at any time. In the event you withdraw your consent, AG may only be able to provide limited services to you;</li>
                                    <li><strong>(access)</strong> access your personal information by contacting our Privacy Officer;</li>
                                    <li><strong>(rectification)</strong> question any personal information AG has on your that may not be accurate or complete. If such enquiry is made by you, AG will take reasonable steps in confirming the accuracy of your personal information with us;</li>
                                    <li><strong>(delete)</strong> request for AG to delete your personal information. AG notes that such request may be subject to legal requirements imposed on us and will notify you of same in the event such request to delete is made by you;</li>
                                    <li><strong>(restrict processing)</strong> to restrict AG’s use of your personal information in specific circumstances. AG notes that such request may be subject to legal requirements imposed on us;</li>
                                    <li><strong>(portability)</strong> to provide you with a copy of your personal information held by us;</li>
                                    <li><strong>(objection)</strong> to object to us processing your personal information; and</li>
                                    <li><strong>(complaint)</strong> to complain to the relevant regulator if you are not satisfied with the outcome of a complaint that you submitted to AG. The European Commission website contains details of the relevant data protection authorities.</li>
                                </ol>
                            </li>
                        </ol>
                    </li>
                </ol>
            </div>
        </div >
    </div >);
};