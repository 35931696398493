import { useContext, useState } from 'react';
import { StoreContext } from 'index';
import { observer } from 'mobx-react-lite';
import { AppHeader, Sidebar, TransmissionsSection } from 'components';

export const Email = observer(({ children }) => {
  const store = useContext(StoreContext);
  const accounts = store?.appStore.getAccounts();

  const [isNavbarOpen, setIsNavbarOpen] = useState(false);

  const hasConnectedAccount = Array.isArray(accounts) && accounts.length > 0;

  const onPressBackToHome = () => {
    setIsNavbarOpen(false);
    store?.appStore.setAccounts([]);
  };

  const [emailValue, setEmailValue] = useState("");

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmailValue(e.target.value);
  };

  if (hasConnectedAccount && store?.appStore.showSidebar) {
    return (
      <div className='flex flex-row scene'>
        <Sidebar
          isOpen={isNavbarOpen}
          connectedAccount={accounts[0]}
          onPressBackToHome={onPressBackToHome}
        />
        <div className='flex flex-column page-content-with-sidebar'>
          <AppHeader
            onPressMenu={() => setIsNavbarOpen(!isNavbarOpen)}
            isNavbarOpen={isNavbarOpen}
            connectedAccount={accounts[0]}
          />
          {children}
          {isNavbarOpen ? (
            <div
              className='open-sidebar-overlay'
              onClick={() => setIsNavbarOpen(false)}
            ></div>
          ) : null}
        </div>
      </div>
    );
  }
  return (
    <div className='scene'>
      <AppHeader
        isNavbarOpen={isNavbarOpen}
        onPressMenu={() => setIsNavbarOpen(!isNavbarOpen)}
        connectWalletCallback={() => setIsNavbarOpen(false)}
      />
      <div className={`page-content${isNavbarOpen ? ' no-scroll' : ''}`}>
        <div style={{
            marginTop: "10%"
        }}>

        </div>
        <TransmissionsSection
        onChangeValue={handleChange}
        inputValue={emailValue}
        />
      </div>
    </div>
  );
});
