import React from "react";
import './TermsAndConditions.scss'

export const TermsAndConditions = () => {
    return (<div className="page-tc">
        <div className="page-tc__container">
            <div className="flex flex-wrap justify-center ">
                <h1>Terms and Conditions</h1>
            </div>
            <div>
                <ol className="ol-heading">
                    <li>
                        Background
                        <ol className="ol-paragraph">
                            <li>
                                These terms and conditions (Terms) are entered into Avocado IDO Limited (AG, we, us or our) and you, together the Parties and each a Party.
                            </li>
                            <li>
                                We provide an online platform that facilitates a Web3 Gaming and Education guild that aims to unlock life-changing opportunities and rewarding experienced for its community members (Users) through its online platform to facilitate gameplay with digital assets (Platform). The Platform is available at avocadodao.io (Website).
                            </li>
                            <li>
                                These Terms are to be read in conjunction with, and incorporate our privacy policy.
                            </li>
                            <li>
                                In these Terms, you means (as applicable) (1) the person or entity registered with us as either a User; or (2) the individual accessing or using the Platform.
                            </li>
                        </ol>
                    </li>
                    <li>
                        Acceptance
                        <ol className="ol-paragraph">
                            <li>You accept these Terms by registering on the Platform, accepting these Terms when prompted or using the Platform or the services.</li>
                            <li>We may amend these Terms at any time, by providing written notice to you. By continuing to use the Platform after you are notified of such amendment(s), you agree to the amended terms. If you decline the amended terms notified to you, your Account will be terminated.</li>
                        </ol>
                    </li>
                    <li>
                        Accounts
                        <ol className="ol-paragraph">
                            <li>You must register on the Platform and create an account (Account) to access the Platform’s features. Each User may only hold one (1) Account on the Platform.</li>
                            <li>You must provide basic information when registering for an Account including your full name, date of birth, billing address, phone number and email address and you must choose a password.</li>
                            <li>You agree to provide accurate, current and complete information during the registration process and regularly update such information to keep it accurate, current and complete.
                                Your Account is personal, and you must not transfer it to others, except with our written permission. If a legal entity such as a company has created an account, you, the legal entity, are the Account owner, and regardless of any change in any contact details, you will remain responsible for your Account as set out in these Terms. If you wish to change the Account owner, you must provide us with a written request to transfer the ownership of the Account to the incoming party, which must also include the incoming party’s written consent to take over full responsibility for the Account.
                            </li>
                            <li>You are responsible for keeping your Account details and password confidential and you will be liable for all activity on your Account, including purchases made using your Account details. You agree to immediately notify us of any unauthorised use of your Account.
                            </li>
                            <li>We reserve the right to review your request for an Account before approving the request. We may request additional information, including your company registration details (if applicable), information about your insurance, licencing documents, and specific information about target markets and/or industries. If you do not provide us with information we reasonably request, we may refuse to create an Account for you. If you provide us with any information which indicates you are not a fit and proper person to be provided with an Account, we may refuse to provide you with an Account, in our sole discretion.</li>
                            <li>We may make access to and use of certain parts of the Platform subject to conditions or requirements, including identity verification, quality of the services and threshold of reviews</li>
                        </ol>
                    </li>
                    <li>
                        Obligations
                        <ol className="ol-paragraph">
                            <li>You will at all times:
                                <ol>
                                    <li>comply with these Terms, all relevant AG policies and all applicable laws and regulations;
                                    </li>
                                    <li>
                                        only post accurate information and representations on the Platform; and
                                    </li>
                                    <li>ensure that you are aware of any laws that apply to you as a User, or in relation to using the Platform.
                                    </li>
                                </ol>
                            </li>
                            <li>You agree that any content (whether provided by AG, a User or a third party) on the Platform may not be used on third party sites or for other business purposes without AG’s prior permission.
                            </li>
                            <li>You must maintain full control of your Account at all times, including but not limited to refraining from allowing third parties to use or access your Account, or by transferring or selling your Account to another person.
                            </li>
                            <li>You must not use the Platform for any illegal or immoral purpose.</li>
                            <li>You agree that any information posted or uploaded on the Platform must not in any way be potentially or actually harmful to AG or any other third party. For the avoidance of doubt, harm includes but is not limited to financial or economic loss that may be suffered by AG. </li>
                            <li>Any information you supply to AG or publish on the Platform must be up to date, and must not
                                <ol>
                                    <li>be false, deceptive, misleading or inaccurate;
                                    </li>
                                    <li>
                                        be fraudulent;
                                    </li>
                                    <li>infringe on any third party’s intellectual property rights, confidentiality or privacy;
                                    </li>
                                    <li>violate any applicable law, statute, ordinance or regulation;
                                    </li>
                                    <li>be defamatory, libellous, threatening or harassing; or
                                    </li>
                                    <li>be obscene or contain inappropriate or unlawful material;</li>
                                </ol>
                            </li>
                            <li>If AG determines in its sole discretion that you have breached any obligation, we reserve the rights to remove any content or offer for services from the Platform, or cancel or suspend your Account</li>
                        </ol>
                    </li>
                    <li>
                        Communication
                        <ol className="ol-paragraph">
                            <li>We may contact you via the Platform using in-Account notifications, or via off-Platform communication channels, such as text message, post or email.
                            </li>
                            <li>Users can communicate privately using our private messaging service once an engagement between Users upon the Platform has been established.
                            </li>
                        </ol>
                    </li>
                    <li>
                        Payment
                        <ol className="ol-paragraph">
                            <li>We may charge fees (and applicable taxes) to Users for use of the Platform. More information of the relevant fees and charges in respect of when fees apply and how they are calculated can be found on the Platform and the AG website. </li>
                            <li>We provide a number of payment methods on the Platform. The payment method you choose may be subject to additional terms and conditions imposed by the applicable third-party payment processor. By making payment through a third-party payment processor, you accept the applicable terms and conditions.</li>
                            <li>In the absence of fraud or mistake, all payments made are final. If you make a payment by debit card or credit card, you warrant that the information you provide to us is true and complete, that you are authorised to use the debit card or credit card to make the payment, that your payment will be honoured by your card issuer, and that you will maintain sufficient funds in your account to cover the payment.
                            </li>
                        </ol>
                    </li>
                    <li>
                        Disclaimer
                        <ol className="ol-paragraph">
                            <li>The Platform and all content thereupon are provided on an ‘as is’ basis and purely for the purposes of facilitating gameplay with digital assets between Users. All engagements between Users are as between themselves and subject to terms agreed between the parties.</li>
                            <li>AG is not liable to Users in respect of any introduction made via the Platform, and all rights which Users may have are solely against other Users, and not AG.
                            </li>
                            <li>For the avoidance of doubt, all warranties are disclaimed by AG, whether express of implied, including but not limited to the following:
                                <ol>
                                    <li>we do not endorse or warrant the existence, conduct, performance, safety, quality or suitability of any User, or content produced by or related thereto; and
                                    </li>
                                    <li>we do not warrant the performance or non-interruption of the Platform and will not be liable in the event that any downtime or interference is experienced.
                                    </li>
                                </ol>
                            </li>
                            <li>The disclaimers contained within these Terms apply to the maximum extent permitted by law. In the event that any User possess statutory rights or warranties of which cannot be disclaimed, the duration of any such statutorily rights or warranties will be limited to the maximum extent permitted by law.
                            </li>
                        </ol>
                    </li>
                    <li>
                        Identify Verification
                        <ol className="ol-paragraph">
                            <li>At all times, we reserve the right to conduct identify variation and/or background checks on any User utilising the Platform. </li>
                            <li>If we choose to conduct identity verification or background checks on any User, to the extent permitted by law, we disclaim all warranties of any kind, either express or implied, that such checks will identify prior misconduct by a User or guarantee that a User will not engage in misconduct in the future.</li>
                            <li>Any verification of Users on the Platform is not an endorsement or recommendation that the User is trustworthy or suitable. You should do your own due diligence before engaging with a User upon the Platform.</li>
                            <li>Following a successful Identity check, you acknowledge and agree that:
                                <ol>
                                    <li>we may permit you to use verification signs, icons or badges in connection with your Account to indicate that a successful Identity check has been performed (Verification Icon); and</li>
                                    <li>a Verification Icon may only be used by the individual or business for whom the relevant Identity Check has been successfully performed and the relevant Verification Icon has been issued, and then, only where that individual is directly providing the relevant goods or services.
                                    </li>
                                </ol>
                            </li>
                            <li>You acknowledge and agree that:
                                <ol>
                                    <li>the Identity check and the issuance of a Verification Icon may not be fully accurate, as they are dependent on the information provided by the relevant individual or business and/or information or checks performed by third parties; and
                                    </li>
                                    <li>you should not rely on the Identity Checks or the Verification Icons, and you should make your own inquiries as to the accuracy, legitimacy, validity, credibility or authenticity of any users of the Platform.
                                    </li>
                                </ol>
                            </li>
                        </ol>
                    </li>
                    <li>
                        Consent
                        <ol className="ol-paragraph">
                            <li>We may allow you to:
                                <ol>
                                    <li>post, upload, publish, send or receive relevant content and information (User Content) on or through our Platform; and/or</li>
                                    <li>access and view User Content and the content and information we make available on the Platform (AG Content and together with User Content, Content)</li>
                                </ol>
                            </li>
                            <li>Unless otherwise indicated, we own or license all rights, title and interest (including Intellectual Property Rights) in our Platform and all of the AG Content. Your use of our Platform and your use of and access to any Content does not grant or transfer to you any rights, title or interest in relation to our Platform or the AG Content.
                            </li>
                            <li>You must not, without the prior written consent of AG or the owner of the Content (as applicable) or except as expressly permitted by these Terms,
                                <ol>
                                    <li>copy or use, in whole or in part, any Content;</li>
                                    <li>reproduce, reverse engineer, retransmit, distribute, disseminate, sell, publish, broadcast or circulate any Content to any third party; or</li>
                                    <li>breach any Intellectual Property Rights connected with our Platform, including by altering or modifying any of the Content, causing any of the Content to be framed or embedded in another website, or creating derivative works from the Content.</li>
                                </ol>
                            </li>
                            <li>Subject to your compliance with these Terms, we grant you a personal, non-exclusive, royalty-free, revocable, worldwide, non-transferable licence to use our Platform and access and view any Content solely for your personal and non-commercial use and use in accordance with these Terms. All other uses are prohibited without our prior written consent.
                            </li>
                            <li>You grant us a non-exclusive, perpetual, irrevocable, worldwide, royalty free, sublicensable and transferable right and licence to use, view, distribute, communicate, copy, store, modify and exploit in any manner the User Content to provide the Platform and promote the Platform in any media or promotional material. You agree that you are solely responsible for all User Content that you make available on or through our Platform. You represent and warrant that
                                <ol>
                                    <li>you are either the sole and exclusive owner of all User Content or you have all rights, licences, consents and releases that are necessary to grant to us the rights in such User Content as contemplated by these Terms; and</li>
                                    <li>neither the User Content nor the posting, uploading, publication, sending or receiving of the User Content or our use of the User Content on, through or by means of our Platform:
                                        <ol>
                                            <li>will infringe, misappropriate or violate a third party’s Intellectual Property Rights, or rights of publicity or privacy, or result in the violation of any applicable law or regulation; and</li>
                                            <li>is incorrect, false, misleading or deceptive.</li>
                                        </ol>
                                    </li>
                                </ol>
                            </li>
                            <li>Despite anything to the contrary, to the maximum extent permitted by law, you agree to indemnify us and hold us harmless in respect of any Liability that we may suffer, incur or otherwise become liable for, arising from or in connection with the User Content you make available on or through the Platform, including as a result of an Intellectual Property Breach.
                            </li>
                            <li>The Content is not comprehensive and is for general information purposes only. It does not take into account your specific needs, objectives or circumstances, and is not advice. While we use reasonable attempts to ensure the accuracy and completeness of the Content, to the extent permitted by law, we make no representation or warranty regarding the Content. The Content is subject to change without notice. We do not undertake to keep our Platform up-to-date and we are not liable if any Content is inaccurate or out-of-date.
                            </li>
                            <li>Without limiting clause 7, AG makes no representation that the Content is accurate, correct or not misleading. Each party should conduct its own independent assessment of any Content and satisfy itself as to the nature of the Content before proceeding with any engagement of another User.
                            </li>
                        </ol>
                    </li>
                    <li>
                        Promotions
                        <ol className="ol-paragraph">
                            <li>From time to time we may make certain offers, or carry out promotions or contests upon the Platform. </li>
                            <li>Additional terms may apply to those offers, promotions or contests and you must agree to those additional terms. In the event of any inconsistency between these Terms and the terms of any offer, promotion or contest, the terms of the offer, promotion or campaign prevail over these Terms to the extent of any inconsistency.</li>
                        </ol>
                    </li>
                    <li>
                        Privacy
                        <ol className="ol-paragraph">
                            <li>AG’s Privacy Policy is available on AG’s website, and contains information in relation to the management of your personal information.
                            </li>
                            <li>AG must comply with the Data Protection Act 2019 when collecting, using, disclosing and managing your personal information.
                            </li>
                            <li>AG will take all reasonable steps to ensure that your personal information is properly protected from misuse, loss, unauthorised access, modification or disclosure</li>
                        </ol>
                    </li>
                    <li>
                        Warranties
                        <ol className="ol-paragraph">
                            <li>You represent, warrant and agree that:
                                <ol>
                                    <li>you will not use our Platform, including the Content, in any way that competes with our business;</li>
                                    <li>there are no legal restrictions preventing you from entering into these Terms;</li>
                                    <li>all information and documentation that you provide to us in connection with these Terms and on the Platform is true, correct and complete;</li>
                                    <li>you have not relied on any representations or warranties made by us in relation to the Platform (including as to whether the Platform is or will be fit or suitable for your particular purposes), unless expressly stipulated in these Terms;</li>
                                    <li>you will be responsible for the use of any part of the Platform, and you must ensure that no person uses any part of the Platform:
                                        <ol>
                                            <li>to break any law or infringe any person’s rights (including Intellectual Property Rights);</li>
                                            <li>to transmit, publish or communicate material that is defamatory, offensive, abusive, indecent, menacing or unwanted; or</li>
                                            <li>in any way that damages, interferes with or interrupts the supply of the Platform;</li>
                                        </ol>
                                    </li>
                                    <li>you are responsible for complying with all laws, rules and regulations which apply to your engagement with other User’s on the Platform.</li>
                                </ol>
                            </li>
                        </ol>
                    </li>
                    <li>
                        Exclusions to Liability
                        <ol className="ol-paragraph">
                            <li>Despite anything to the contrary, to the maximum extent permitted by law, we will not be liable for, and you waive and release us from and against, any Liability caused or contributed to by, arising from or connected with:
                                <ol>
                                    <li>your breach or non-compliance with these Terms;</li>
                                    <li>your or your personnel’s acts or omissions;</li>
                                    <li>the use or results of any identity check;</li>
                                    <li>your engagement with a User;</li>
                                    <li>any use or application of the Platform or the AG services by a person or entity other than you, or other than as reasonably contemplated by these Terms;</li>
                                    <li>any aspect of User interaction;</li>
                                    <li>any Content uploaded to the Platform;</li>
                                    <li>any works, services, goods, materials or items which do not form part of the Platform or the AG services (as expressed in these Terms), or which have not been provided by us;</li>
                                    <li>any third parties or any goods and services provided by third parties, including Users, end users, suppliers, transportation or logistics providers or other subcontractors which the provision of the Platform may be contingent on, or impacted by;</li>
                                    <li>the Platform or the AG services being unavailable, or any delay in us providing the AG services to you, for whatever reason; and/or</li>
                                    <li>any event outside of our reasonable control</li>
                                </ol>
                            </li>
                            <li>This clause will survive the termination or expiry of these Terms</li>
                        </ol>
                    </li>
                    <li>
                        Limitations on Liability
                        <ol className="ol-paragraph">
                            <li>Despite anything to the contrary, to the maximum extent permitted by law:
                                <ol>
                                    <li>we will not be liable for consequential loss; and</li>
                                    <li>our Liability for any Liability under these Terms will be reduced proportionately to the extent the relevant Liability was caused or contributed to by your acts or omissions or that of your personnel</li>
                                </ol>
                            </li>
                            <li>This clause will survive the termination or expiry of these Terms.</li>
                        </ol>
                    </li>
                    <li>
                        Termination
                        <ol className="ol-paragraph">
                            <li>Your Account and these Terms may be terminated by you at any time, using the ‘delete Account’ functionality (or similar) in your Account settings.</li>
                            <li>We may terminate these Terms and/or your Account for any reason by giving you notice.</li>
                            <li>We may suspend your Account or terminate these Terms and/or your Account immediately upon written notice to you, if:
                                <ol>
                                    <li>you breach any provision of these Terms and that breach has not been remedied within 3 business days of being notified by us;</li>
                                    <li>you repeatedly receive poor reviews;</li>
                                    <li>there is any reason outside our control which has the effect of compromising our ability to provide the AG services; or</li>
                                    <li>you are unable to pay your debts as they fall due</li>
                                </ol>
                            </li>
                            <li>Upon expiry or termination of these Terms or your Account:
                                <ol>
                                    <li>we will remove your access to the Platform;</li>
                                    <li>we will immediately cease providing the AG services;</li>
                                    <li>you must pay all outstanding amounts due to us;</li>
                                    <li>you agree that any payments made by you to us are not refundable to you; and</li>
                                    <li>where we terminate the Terms or your Account for any reason, you also agree to pay us our additional costs arising from, or in connection with, such termination.</li>
                                </ol>
                            </li>
                            <li>Termination of these Terms or your Account will not affect any rights or liabilities that a Party has accrued under it.</li>
                            <li>This clause will survive the termination or expiry of these Terms or your Account.</li>
                        </ol>
                    </li>
                    <li>
                        Independent Advice
                        <ol className="ol-paragraph">
                            <li>You acknowledge and represent to AG that you have had the opportunity to seek and obtain separate and independent legal advice before entering into these Terms.</li>
                            <li>If you have accepted these Terms without first obtaining legal advice, you have done so at your sole and absolute discretion, and will not be entitled to rely upon the absence of legal advice as a defence to any breaches of these Terms.</li>
                        </ol>
                    </li>
                    <li>
                        General
                        <ol className="ol-paragraph">
                            <li><strong>Assignment:</strong> You must not assign or deal with the whole or any part of your rights or obligations under these Terms without our prior written consent</li>
                            <li><strong>Email:</strong> You agree that we are able to send electronic mail to you and receive electronic mail from you. You release us from any Liability you may have as a result of any unauthorised copying, recording, reading or interference with that document or information after transmission, for any delay or non-delivery of any document or information and for any damage caused to your system or any files by a transfer.</li>
                            <li><strong>Force Majeure:</strong> We will not be liable for any delay or failure to perform our obligations under these Terms if such delay is due to any circumstance beyond our reasonable control.</li>
                            <li><strong>Governing law:</strong> These Terms governed by the laws of New South Wales. Each Party irrevocably and unconditionally submits to the exclusive jurisdiction of the courts operating in New South Wales and any courts entitled to hear appeals from those courts and waives any right to object to proceedings being brought in those courts.</li>
                            <li><strong>Notices:</strong> Any notice given under these Terms must be in writing addressed to us at the address at the end of these Terms or to you at the address in your Account. Any notice may be sent by standard post or email and will be deemed to have been served on the expiry of 48 hours in the case of post, or at the time of transmission in the case of transmission by email.</li>
                            <li><strong>Relationship of Parties:</strong> These Terms are not intended to create a partnership, joint venture, employment or agency relationship between the Parties.</li >
                            <li><strong>Severance:</strong> If a provision of these Terms is held to be void, invalid, illegal or unenforceable, that provision is to be read down as narrowly as necessary to allow it to be valid or enforceable, failing which, that provision(or that part of that provision) will be severed from these Terms without affecting the validity or enforceability of the remainder of that provision or the other provisions in these Terms.</li >
                        </ol>
                    </li>
                    <li>
                        Definitions
                        <ol className="ol-paragraph">
                            <li>Defined terms may appear in the body of these Terms and in this clause.</li>
                            <li>Consequential Loss includes any consequential loss, indirect loss, real or anticipated loss of profit, loss of benefit, loss of revenue, loss of business, loss of goodwill, loss of opportunity, loss of savings, loss of reputation, loss of use and/or loss or corruption of data, whether under statute, contract, equity, tort (including negligence), indemnity or otherwise.</li>
                            <li>Intellectual Property means any domain names, know-how, inventions, processes, trade secrets or confidential information; or circuit layouts, software, computer programs, databases or source codes, including any application, or right to apply, for registration of, and any improvements, enhancements or modifications of, the foregoing.</li>
                            <li>Intellectual Property Rights means for the duration of the rights in any part of the world, any industrial or intellectual property rights, whether registrable or not, including in respect of Intellectual Property.</li>
                            <li>Intellectual Property Breach means any breach by you (or any of your Personnel) of any of our Intellectual Property Rights (or any breaches of third-party rights including any Intellectual Property Rights of third parties).</li>
                            <li>Liability means any expense, cost, liability, loss, damage, claim, notice, entitlement, investigation, demand, proceeding or judgment (whether under statute, contract, equity, tort (including negligence), indemnity or otherwise), howsoever arising, whether direct or indirect and/or whether present, unascertained, future or contingent and whether involving a third party or a party to these Terms or otherwise.</li>
                        </ol>
                    </li>
                    <div className="flex flex-wrap justify-center ">
                        <h1>NFT Terms</h1>
                    </div>
                    <li>
                        Applicability of NFT Terms
                        <ol className="ol-paragraph">
                            <li>When provided with non-fungible tokens (NFT) by AG for use upon the Platform, you must agree to the terms and conditions set out below (NFT Terms), which apply to any subsequent person to whom you may sell and/or transfer your NFT’s provided by us in the first instance.</li>
                            <li>All NFTs provided by AG are subject to these NFT Terms, unless otherwise agreed in writing, and by receiving any NFT you agree to be bound by these NFT Terms.</li>
                            <li>The use of any NFTs with AG is subject to these NFT Terms together with any other terms and conditions which may apply to the use of the Platform.</li>
                            <li>The termination of these NFT Terms will not effect any obligations which have arisen prior to termination.</li>
                        </ol>
                    </li>
                    <li>
                        Provision of NFTs
                        <ol className="ol-paragraph">
                            <li>AG may at any time provide the User with NFTs from time to time for use upon the Platform, either as a stand-alone or with bundles, including for expansions of the AG game.</li>
                            <li>AG may require that the User to:
                                <ol>
                                    <li>register an Account with us prior to any transfer of an NFT; and</li>
                                    <li>nominate your Digital Wallet at the time that the NFT is provided, or nominate a Digital Wallet at a later time, for delivery of any provided NFT.</li>
                                </ol>
                            </li>
                            <li>Title in any NFT provided to a User by AG remains with AG at all times notwithstanding any of the provision of the NFT by AG.</li>
                            <li>AG may, at any time prior to the provision of any NFT to the User, order to User to recall possession of the NFT in their sole and absolute discretion, so that the NFT is returned to AG.</li>
                        </ol>
                    </li>
                    <li>
                        NFT IP
                        <ol className="ol-paragraph">
                            <li>No transfer of intellectual property
                                <p>Except as expressly stated in these NFT Terms, nothing in these NFT Terms are intended to give you ownership of any Intellectual Property Rights in, or other rights in respect of, any trademarks, business names, logos, trading styles, get-up, processes, methodologies or other intellectual property belonging to us or our licensors. All ownership of Intellectual Property Rights in the NFTs remains with the creator.
                                </p>
                            </li>
                            <li><strong>Grant of Licence</strong></li>
                            <ol>
                                <li>Subject to your continued compliance with these NFT Terms, we grant you a non-exclusive, transferable, worldwide, royalty free and limited licence to use, copy and display the design and art associated with the NFTs that have been transferred.</li>
                                <li>You agree that you may not, nor permit any third party to, without our written consent:
                                    <ol>
                                        <li>modify the art or design associated with an NFT that has been transferred to you;</li>
                                        <li>use such NFT to promote, advertise, market or sell any third-party product or service;</li>
                                        <li>use the NFT in connection with images, videos or other media which depict hatred, intolerance, violence or cruelty;</li>
                                        <li>attempt to trademark or copyright other acquire additional intellectual property rights to the design or art associated with the NFT; or</li>
                                        <li>use the NFT for any commercial benefit.</li>
                                    </ol>
                                </li>
                                <li>Your licence terminates immediately if you:
                                    <ol>
                                        <li>use the NFT in any way which would be reasonably considered to bring the Intellectual Property Rights owner or AG into disrepute, or which would be reasonably seen to disparage the Intellectual Property Rights owner or AG;</li>
                                        <li>use the NFT for any unlawful or illegal purpose; or</li>
                                        <li>sell the NFT and do not ensure that the buyer is notified of and agrees to be bound by these NFT Terms.</li>
                                    </ol>
                                </li>
                            </ol>
                        </ol>
                    </li>
                    <li>
                        Termination of NFT Terms
                        <ol className="ol-paragraph">
                            <li>These NFT Terms may be terminated with immediate effect:
                                <ol>
                                    <li>If one of the warranties in clause 24 is breached; or</li>
                                    <li>by either party if the other party commits any material breach of its obligations under these Terms and fails to remedy such breach (if capable of remedy) within 30 days of receipt of notice from the non-defaulting party requiring it to do so.</li>
                                </ol>
                            </li>
                            <li>Termination of these Terms does not affect the rights of the parties which have accrued prior to termination</li>
                        </ol>
                    </li>
                    <li>
                        NFT Acknowledgments
                        <ol className="ol-paragraph">
                            <li>You acknowledge and agree that:
                                <ol>
                                    <li>we provide NFTs solely on a proprietary basis for use with AG and if we transact with you, we do so solely on a bilateral basis;</li>
                                    <li>NFTs are not intended for speculative use, are not sold or represented to be financial product and nothing we publish is in any way financial advice to you or any other person;</li>
                                    <li>NFTs experience may have extreme price volatility, including being worthless in the future;</li>
                                    <li>we are not providing and will not provide any fiduciary, advisory, brokerage, exchange or other similar services to you or any other person;</li>
                                    <li>you are solely responsible for any decision to enter into a transaction subject to these NFT Terms, including the evaluation of any and all risks related to any such transaction;</li>
                                    <li>a significant degree of IT sophistication is required to safely deal in and store NFTs of any kind using a Digital Wallet;</li>
                                    <li> we are not responsible for any Loss caused by your failure to act in accordance with our policies, procedures or in accordance with our reasonable directions;</li>
                                    <li>you understand and acknowledge that your ownership of NFTs remains contingent upon you remaining in control of the seed phrases and private key associated with your Digital Wallet and that we will not store any information in connection with your Digital Wallet beyond that required for the sale of NFTs or the interaction of your Digital Wallet with AG;</li>
                                    <li>we do not and cannot guarantee there will be any use for, or any particular price available for any NFT provided by us; and</li>
                                    <li>we do not represent or guarantee any outcomes, or any financial return from your acquisition of any NFT from us, save the ability to use the NFT within AG for such time as we choose to support and off AG, and such access to AG may be subject to further terms and conditions, including payment of subscription fees.</li>
                                </ol>
                            </li>
                        </ol>
                    </li>
                </ol>
                <li>
                    NFT Warranties
                    <ol className="ol-paragraph">
                        <li><strong>AG Warranties:</strong>
                            <p>AG represent and warrant that:</p>
                            <ol>
                                <li>we own, or use under licence, the Intellectual Property Rights in the NFTs which we distribute, and are legally entitled to, and are capable of, distributing the NFTs offered;</li>
                                <li>we will give you notice before we discontinue or alter the rights or features of any NFTs which have been distributed to you, subject to any other terms and conditions applying to AG;</li>
                                <li>we will undertake reasonable efforts to deliver NFTs within the planned time indicated for delivery</li>
                            </ol>
                        </li>
                        <li><strong>User Warranties:</strong>
                            <p>Users warrant and assure AG that in taking possession of an NFT:</p>
                            <ol>
                                <li>you are sufficiently experienced and educated to make decisions regarding the procurement of NFTs from us, including sufficient experience in dealing with and storing NFTs using a Digital Wallet;</li>
                                <li>you have all necessary experience, resources, certificates, licences, permits and approvals to procure NFTs applicable in Your Jurisdiction, and that any transactions under these NFT Terms or in your use of the platform will be legal under the applicable laws of your Jurisdiction;</li>
                                <li>all information you supply to us is true and accurate as at the time it is given, and that any Digital Wallet address you provide to us has been generated in accordance with best practice security measures and no other party, other than you or your authorised representative, has used, or has access to, the seed phrases, private keys or analogous passwords required to effect transfers from, the Digital Wallet;</li>
                                <li>as far as you are aware, there are no facts, circumstances or other information which:
                                    <ol>
                                        <li>you have not fully and fairly disclosed to us in a manner and to an extent that it would impact our ability to make a reasonable assessment of those facts, matters and circumstances prior to entering into a transaction to provide you with an NFT; and</li>
                                        <li>is of such nature and materiality that a reasonable person, had it been made aware of, could not reasonably be expected to consider prior to entering into a transaction for the sale of NFTs;</li>
                                    </ol>
                                </li>
                                <li>you are not involved in any capacity in any claim, legal action, proceeding, suit, litigation, prosecution, investigation, enquiry, mediation or arbitration (nor which are pending or threatened) concerning NFTs;</li>
                                <li>if we request, you will identify and substantiate the source of funds involved in transactions to acquire NFTs;</li>
                                <li>you are the lawful owner of any Digital Wallet nominated for delivery of NFTs and each Digital Wallet is owned and operated solely for your benefit, and no person has any right, title or interest in you nominated Digital Wallet; and</li>
                                <li>you have had the opportunity to obtain independent legal advice in relation to the terms and effect of these Terms.</li>
                            </ol>
                        </li>
                        <li><strong>Continuous Warranties</strong>
                            <p>You represent and warrant to us that each of the User Warranties is true and accurate, and not misleading or deceptive as at the date of these NFT Terms and, except as expressly stated, will be true, accurate and not misleading or deceptive each time a NFTs are provided to you.</p>
                        </li>
                        <li><strong>Notification</strong>
                            <p>You must disclose to us anything that has or will constitute a material breach of a Purchaser’s Warranty or cause a Purchaser’s Warranty to be untrue or inaccurate, as soon as practicable after you become aware of it.</p>
                        </li>
                        <li><strong>Mutual Warranties</strong>
                            <p>Each party warrants and assures the other party that:</p>
                            <ol>
                                <li>if it is a company, it is duly incorporated and validly exists under the law of its place of incorporation;</li>
                                <li>it is not subject to an Insolvency Event; and</li>
                                <li>these terms constitute a legal, valid and binding terms enforceable in accordance with its terms by appropriate legal remedy.</li>
                            </ol>
                        </li>
                        <li><strong>Knowledge and Awareness</strong>
                            <p>Where a warranty is given ‘to the best of a party’s knowledge, belief and awareness’, or ‘as far as the party is aware’ or with a similar qualification as to the relevant party’s awareness or knowledge, the party giving the warranty will be deemed to know or be aware of a particular fact, matter or circumstance if that party’s directors or senior management employees are aware of that fact, matter or circumstance, or would have become aware if they had made reasonable enquires as at the date of these NFT Terms.</p>
                        </li>
                    </ol>
                </li>
                <li>
                    Indemnity
                    <ol className="ol-paragraph">
                        <li>You agree to indemnify us and our Personnel and will keep us and our Personnel indemnified at all times to the fullest extent permitted by law in respect of any Loss or Claim which we or our Personnel may suffer, sustain or incur arising from, or connected with, a breach of a User Warranty without limitation.</li>
                        <li>In addition, you must indemnify us and our Personnel and keep us and our Personnel indemnified at all times to the fullest extent permitted by law in respect of any Claim which we or our Personnel may suffer, sustain or incur arising from, or connected with, any breach of these NFT Terms, other than a breach of a User Warranty, or any breach of all applicable laws, reduced to the extent of the Loss in respect of the Claim was caused by the negligent act or omission of us or our Personnel.</li>
                    </ol>
                </li>
                <li>
                    Limitations of Liability
                    <ol className="ol-paragraph">
                        <li><strong>Limitation</strong>
                            <p>In the absence of a material breach of these NFT Terms by AG or the gross negligence, fraud or wilful misconduct by us when providing NFTs to you under these NFT Terms, we will not be liable to you on account of anything done, omitted or suffered by us acting in good faith when providing NFTs to you pursuant to these NFT Terms, including in respect of a Force Majeure Event.</p>
                        </li>
                        <li><strong>Third Party Services </strong>
                            <p>Subject always to clause 26.1, AG excludes any liability for the performance, errors or omissions of unaffiliated, nationally or regionally recognised third parties or decentralised networks such as, by way of example and not limitation, blockchain networks (whether private or public) courier companies, national postal services and other delivery, telecommunications and other companies not under our reasonable control, and third parties not under our reasonable control providing services to the blockchain industry generally, such as, by way of example and not limitation, companies and other entities providing processing and payment or transaction services (including “Layer 2” services), banking partners, custody services, market making services and/or third party pricing services and decentralised blockchain networks such as, by way of example and not limitation, the blockchain(s) upon which any NFT depends or forks of those blockchain(s)</p>
                        </li>
                        <li><strong>Compliance with Laws – no monitoring responsibilities</strong>
                            <ol>
                                <li>AG will have no liability or responsibility for your compliance with laws or regulations governing the transfer and use of NFTs. Further, you are solely responsible for compliance with all applicable requirements of any laws, rules, and regulations of governmental authorities in Your Jurisdiction
                                </li>
                                <li>
                                    The User acknowledges that neither we or any of our Personnel is, and will not be, by virtue of providing NFTs to you, an advisor or fiduciary to you.
                                </li>
                            </ol>
                        </li>
                        <li><strong>No Consequential Loss</strong>
                            <p>Neither party to these NFT Terms will be liable to the other for any Claim, howsoever arising, for loss of profits, loss of opportunity, loss of expectation, loss of chance, or in the nature of an indirect or consequential loss</p>
                        </li>
                        <li><strong>Liability Cap</strong>
                            <p>You agree that our liability to you arising in respect of any matters in connection with these NFT Terms are limited to the greater of:</p>
                            <ol>
                                <li>
                                    the total amount paid to us for any NFT, in respect of a claim concerning an NFT; or
                                </li>
                                <li>USD $100.00.</li>
                            </ol>
                        </li>
                    </ol>
                </li>
                <li>
                    NFT Definitions
                    <ol className="ol-paragraph">
                        <li>In these NFT Terms
                            <ol>
                                <li><strong>Claim</strong> includes a claim, notice, demand, action, proceeding, litigation, investigation, however arising whether present, unascertained, immediate, future or contingent, whether based in contract, tort or statute and whether involving a third party or a party to these Terms and where and to the extent the context permits, includes all associated Loss;</li>
                                <li><strong>Digital Currency</strong> means a cryptographically secured virtual currency or virtual asset which we identify as an acceptable means of payment or transacting with us;</li>
                                <li><strong>Digital Wallet</strong> means the applicable location, wallet, address, account or storage device designated by you for the delivery of the NFTs provided by us;</li>
                                <li><strong>Insolvency Event</strong> means the happening of any of the following events in relation to a body corporate:
                                    <ol>
                                        <li>the body corporate becomes an externally-administered body corporate</li>
                                        <li>a person is appointed administrator, receiver, provisional liquidator, trustee for creditors in bankruptcy or an analogous appointment is made in respect of the body corporate;</li>
                                        <li>the body corporate suspends payment of its debts, or enters, or takes any step towards entering, a compromise or arrangement with, or assignment for the benefit of, any of its members or creditors;</li>
                                        <li>a secured creditor of the body corporate enforces its security in relation to its debt for an amount in excess of $50,000; or</li>
                                        <li>the body corporate is, or its directors state that it is, unable to pay its debts as and when they become due and payable;</li>
                                    </ol>
                                </li>
                                <li><strong>Intellectual Property Rights</strong> means all present and future intellectual and industrial property rights conferred by statute, at common law or in equity and wherever existing, including:
                                    <ol>
                                        <li>patents, inventions designs, copyright, trade marks, brand names, product names, domain names or rights in designs, art, images, drawings, know how, product names, trade secrets and any other rights subsisting in the results of intellectual effort in any field, whether or not registered or capable of registration</li>
                                        <li>any application or right to apply for registration of any of these rights</li>
                                        <li>any registration of any of those rights or any registration of any application referred to in paragraph (b); and</li>
                                        <li>all renewals and extensions of these rights;</li>
                                    </ol>
                                </li>
                                <li><strong>Loss</strong> includes and loss, damage, cost, charge, liability or expense (including legal costs and expenses);</li>
                                <li><strong>Personnel</strong> means any employee, contractor, subcontractor, agent, partner, shareholder, ultimate beneficial owner, director or officer of a party;</li>
                            </ol>
                        </li>
                    </ol>
                </li>
            </div>
        </div >
    </div >);
};