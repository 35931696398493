import React, { useCallback, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { Link } from "react-router-dom";
import "./Games.scss";
import "./MainGamesNews.scss";
import WhitepaperImg from "../../../assets/images/games/whitepaper.png";
import WithdrawImg from "../../../assets/images/games/withdraw.png";
import VisitImg from "../../../assets/images/games/visit.png";
import axios from "axios";

type Props = {};

interface PreviewGameInterface {
  name: string;
  gameBackground: string;
  description: string;
  websiteUrl: string;
  whitepaper: string;
  tokenGuide: string;
  gameIcon: string;
}

const baseApiUrl = process.env.REACT_APP_BASE_API_AVOCADO || "";

export const Games = observer(({}: Props) => {
  const [previewGame, setPreviewGame] = useState<PreviewGameInterface>({
    name: "",
    gameBackground: "",
    description: "",
    websiteUrl: "",
    whitepaper: "",
    tokenGuide: "",
    gameIcon: "",
  });
  const [previewGameId, setPreviewGameId] = useState("");
  const [listGame, setListGame] = useState<any[]>([]);

  const [menuSection, setMenuSection] = useState(false);
  const [selectedGameIndex, setSelectedGameIndex] = useState(0);
  const [togglingPreview, setTogglingPreview] = useState(false);

  // Timeout State
  const [intervalFade, setIntervalFade] = useState<any>(null);
  const [switchGameTimeout, setSwitchGameTimeout] = useState<any>(null);

  const getGameList = async () => {
    // Do get games api call
    try {
      // check response code success
      axios
        .request({
          url: baseApiUrl + "/public/game/list",
        })
        .then((response: any) => {
          setListGame(response.data.body.response.gamesData);
        });
    } catch (error) {
      console.log("err: ", error);
    }
  };

  const setGamePreview = (index: any) => {
    if (!listGame.length) return false;
    setPreviewGame(listGame[index].attributes);
    setPreviewGameId(listGame[index].id);

    // to toggle animation class
    setTogglingPreview(true);
    setIntervalFade(
      setTimeout(() => {
        setTogglingPreview(false);
      }, 1000)
    );
  };

  const webChecker = (web: any) => {
    let link = web;
    if (!web.includes("http")) {
      link = "https://" + web;
    }
    return link;
  };

  const handleMenuSection = () => {
    setMenuSection(!menuSection);
  };

  const handleHoverGame = (index: any) => {
    clearTimeout(switchGameTimeout);
    setGamePreview(index);
    setSelectedGameIndex(index);
  };

  const switchGamePreview = () => {
    // return if no data
    if (!listGame.length) return false;
    setSwitchGameTimeout(
      setTimeout(() => {
        setSelectedGameIndex((currentChecker) => {
          const checker =
            currentChecker + 1 >= listGame.length ? 0 : currentChecker + 1;
          setGamePreview(checker);
          return checker;
        });
        switchGamePreview();
      }, 3000)
    );
  };

  useEffect(() => {
    getGameList();
  }, []);

  useEffect(() => {
    if (listGame.length > 0) {
      setGamePreview(0);
      switchGamePreview();
    }
  }, [listGame]);

  useEffect(() => {
    return () => {
      clearTimeout(intervalFade);
      clearTimeout(switchGameTimeout);
    };
  }, []);

  return (
    <div className="main-games">
      {previewGame.name ? (
        <div
          className={togglingPreview ? "game__wrapper fade" : "game__wrapper"}
        >
          <div
            className="game__highlight"
            style={{
              backgroundImage: "url(" + previewGame.gameBackground + ")",
            }}
          ></div>
          <div className="game__info">
            <div className="game__description">
              <div>
                <div className="game__title text-uppercase">
                  {previewGame.name}
                </div>
                <div>{previewGame.description}</div>
              </div>
              <div className="game__btn-container">
                <a
                  href={webChecker(previewGame.websiteUrl)}
                  target="_blank"
                  className="game__button-item game__button-item-mobile fs-12"
                >
                  Visit Website
                </a>
                <a
                  href={webChecker(previewGame.whitepaper)}
                  target="_blank"
                  className="game__button-item fs-12"
                >
                  Whitepaper
                  <img
                    src={WhitepaperImg}
                    alt="whitepaper-img"
                    className="game__menu-item-img"
                  />
                </a>

                <div className="game__menu-container">
                  <button
                    className="game__button-item game__button--menu"
                    onClick={() => {
                      handleMenuSection();
                    }}
                  >
                    <span className="game__button-text-menu fs-12">...</span>
                  </button>
                  {menuSection ? (
                    <div className="game__menu-section">
                      <a
                        href={webChecker(previewGame.tokenGuide)}
                        target="_blank"
                        className="game__menu-item fs-12"
                      >
                        How to Withdraw
                        <img
                          src={WithdrawImg}
                          alt="withdraw-img"
                          className="game__menu-item-img"
                        />
                      </a>
                      <Link to={"/games/" + previewGameId}>
                        <button className="game__menu-item fs-12">
                          Read More ...
                        </button>
                      </Link>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>

              <div className="game__navigation">
                <a
                  href={webChecker(previewGame.websiteUrl)}
                  className="game__button game__button-website-mobile fs-12"
                  target="_blank"
                >
                  Visit website
                  <img
                    src={VisitImg}
                    alt="visit-icon"
                    className="game__button-icon"
                  />
                </a>
                <a
                  href={webChecker(previewGame.whitepaper)}
                  className="game__button fs-12"
                  target="_blank"
                >
                  Whitepaper
                  <img
                    src={WhitepaperImg}
                    alt="whitepaper-icon"
                    className="game__button-icon"
                  />
                </a>
                <a
                  href={webChecker(previewGame.tokenGuide)}
                  className="game__button fs-12"
                  target="_blank"
                >
                  How to Withdraw
                  <img
                    src={WithdrawImg}
                    alt="withdraw-icon"
                    className="game__button-icon"
                  />
                </a>
                <Link to={"/games/" + previewGameId}>
                  <button className="game__button fs-12">Read More</button>
                </Link>
              </div>
            </div>
            <img
              src={previewGame.gameIcon}
              alt="game-preview"
              className="game__preview"
            />
          </div>
          <div className="game__separator"></div>
        </div>
      ) : (
        <></>
      )}
      <div className="game__main-container">
        <div className="game__list-title mt-1">Our Games List</div>
        <ul className="game__list">
          {listGame.map((game: any, index: number) => {
            return (
              <Link to={"/games/" + game.id}>
                <li
                  className={
                    selectedGameIndex === index
                      ? "game__item game__item--active"
                      : "game__item"
                  }
                  onMouseEnter={() => handleHoverGame(index)}
                  onMouseLeave={() => switchGamePreview()}
                  key={"game-" + index}
                >
                  <img
                    src={game.attributes.gameIcon}
                    alt={game.attributes.name}
                    width="100%"
                  />
                </li>
              </Link>
            );
          })}
        </ul>
      </div>
    </div>
  );
});
