import React, { useCallback, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import "./Games.scss";
import { useParams } from "react-router-dom";
import WithdrawImg from "../../../assets/images/games/withdraw.png";
import WhitepaperImg from "../../../assets/images/games/whitepaper.png";
import PrerequisiteImg from "../../../assets/images/games/prerequisite.png";
import axios from "axios";

type Props = {};

const baseApiUrl = process.env.REACT_APP_BASE_API_AVOCADO || "";

interface GameInterface {
  name: string;
  websiteUrl: string;
  descriptions: string;
  signupLink: string;
  gameDetailBackground: string;
  tokenGuide: string;
  whitepaper: string;
  prerequisite: string;
  youtubeLink: string;
  additionalInfo: string;
  gameDetailIcon: string;
}

export const GamesDetail = observer(({}: Props) => {
  const { id }: any = useParams();
  const [game, setGame] = useState<GameInterface>({
    name: "",
    websiteUrl: "",
    descriptions: "",
    signupLink: "",
    gameDetailBackground: "",
    tokenGuide: "",
    whitepaper: "",
    prerequisite: "",
    youtubeLink: "",
    additionalInfo: "",
    gameDetailIcon: "",
  });
  const [gameId, setGameId] = useState(id);
  const [menuSection, setMenuSection] = useState(false);

  const getGameDetail = useCallback(async () => {
    // Do get games api call
    try {
      // check response code success
      axios
        .request({
          url: baseApiUrl + "/public/game/detail/" + gameId,
        })
        .then((response: any) => {
          setGame(response.data.body.response.game.attributes);
        });
    } catch (error) {
      console.log("err: ", error);
    }
  }, []);

  const handleInnerHTML = useCallback((html) => {
    return { __html: html };
  }, []);

  const handleMenuSection = useCallback(() => {
    setMenuSection(!menuSection);
  }, [menuSection]);

  useEffect(() => {
    getGameDetail();
  }, []);

  return (
    <div className="main-games main-games--detail">
      <div className="game-detail__wrapper">
        <div
          className="game-detail__background"
          style={{
            backgroundImage: "url(" + game.gameDetailBackground + ")  ",
          }}
        ></div>
        <div className="game-detail__container">
          {menuSection ? (
            <div
              className="game-detail__layer"
              onClick={() => setMenuSection(false)}
            ></div>
          ) : (
            <></>
          )}
          <div className="game-detail__left-container">
            <div className="game-detail__top-section">
              <p className="game-detail__title fs-24">{game.name}</p>
              <p className="game-detail__web fs-16">{game.websiteUrl}</p>
              <p className="game-detail__desc fs-16">{game.descriptions}</p>
              <div className="game-detail__btn-container">
                {game.signupLink ? (
                  <a
                    href={game.signupLink}
                    className="game-detail__button fs-16"
                    target="_blank"
                  >
                    Apply Now
                  </a>
                ) : (
                  <></>
                )}
                <a
                  href={game.websiteUrl}
                  className="game-detail__button fs-16"
                  target="_blank"
                >
                  Visit Website
                </a>
                <div className="game-detail__menu-container">
                  <button
                    className="game-detail__button game-detail__button--menu"
                    onClick={() => {
                      handleMenuSection();
                    }}
                  >
                    <span className="game-detail__button-text-menu">...</span>
                  </button>
                  {menuSection ? (
                    <div className="game-detail__menu-section">
                      <a
                        href={game.tokenGuide}
                        className="game-detail__menu-item"
                        target="_blank"
                      >
                        How To Withdraw
                        <img
                          src={WithdrawImg}
                          alt="withdraw-img"
                          className="game-detail__menu-item-img"
                        />
                      </a>
                      <a
                        href={game.whitepaper}
                        className="game-detail__menu-item"
                        target="_blank"
                      >
                        Whitepaper
                        <img
                          src={WhitepaperImg}
                          alt="whitepaper-img"
                          className="game-detail__menu-item-img"
                        />
                      </a>
                      <a
                        href={game.prerequisite}
                        className="game-detail__menu-item"
                        target="_blank"
                      >
                        Prerequisite
                        <img
                          src={PrerequisiteImg}
                          alt="prerequisite-img"
                          className="game-detail__menu-item-img"
                        />
                      </a>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
            <div className="game-detail__bottom-section">
              <p className="game-detail__title-bottom fs-16">Game Preview</p>
              <iframe
                className="game-detail__video"
                width="100%"
                height="322"
                src={game.youtubeLink}
                frameBorder="0"
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              ></iframe>
              {game.additionalInfo ? (
                <div
                  className="game-detail__additional-info"
                  dangerouslySetInnerHTML={handleInnerHTML(game.additionalInfo)}
                ></div>
              ) : (
                <></>
              )}
            </div>
          </div>
          <div className="game-detail__right-container">
            <img
              src={game.gameDetailIcon}
              alt="cover-img"
              className="game-detail__cover-img"
            />
          </div>
        </div>
      </div>
    </div>
  );
});
