import React, { CSSProperties } from "react";
import AvocadoDAOLogo from "../../assets/images/logos/avo-full-logo.png";
import CertikLogo from "../../assets/images/icons/certik-logo.png";
import { CopyrightSection } from "./CopyrightSection";
import { FooterLinks } from "./FooterLinks";
import { SocialMediaLinks } from "./SocialMediaLinks";

const logoHeight = 60;
const certikLogoHeight = 30;
const certikReport = 'https://www.certik.com/projects/avocado-dao';


function FooterSection() {
  const { innerWidth: width } = window;
  const TABLET_WIDTH = 870;
  return (
    <div className="flex flex-column align-center footer-section">
      <div className="flex justify-space-between footer-components">
        {width > TABLET_WIDTH ? (
          <div>
            <div className="footer-logo">
              <img
                src={AvocadoDAOLogo}
                alt="Avocado DAO logo"
                height={logoHeight}
              />
            </div>
            <div style={{ display: 'flex', flexDirection: "row" }}>
              <p style={{ marginRight: '10px' }} className='footer-link'>Audited by</p>
              <a
                href={certikReport}
                target='_blank'
                rel='noreferrer noopener'
                style={{ textDecoration: 'none' }}
              >
                <img
                  src={CertikLogo}
                  alt="Certik logo"
                  height={certikLogoHeight}
                />
              </a>
            </div>
          </div>
        ) : null}
        <FooterLinks />
        {width <= TABLET_WIDTH ? (
          <div className="footer-logo">
            <img
              src={AvocadoDAOLogo}
              alt="Avocado DAO logo"
              height={logoHeight}
            />
          </div>
        ) : null}
        <SocialMediaLinks />
      </div>
      <CopyrightSection />
    </div>
  );
}

export { FooterSection };
