import React from 'react';

type Props = {
  onPressLeftBtn?: () => void;
  scholarshipInfo?: string;
  scholarshipTitle?: string;
  usdYieldInfo?: string;
  usdYieldTitle?: string;
  discordInfo?: string;
  discordTitle?: string;
  gamesInfo?: string;
  gamesTitle?: string;
  gamesFiInfo?: string;
  gamesFiTitle?: string;
};

function HeaderMainSection({
  onPressLeftBtn = () => { },
  scholarshipInfo = '11,000+',
  scholarshipTitle = 'Scholarships',
  usdYieldInfo = '95 M',
  usdYieldTitle = 'SLP Yielded',
  discordInfo = '67,000+',
  discordTitle = 'Discord Members',
  gamesInfo = '5',
  gamesTitle = 'Games with Scholarships',
  gamesFiInfo = '45+',
  gamesFiTitle = 'GameFi Projects Invested',
}: Props) {
  return (
    <div className='header-main-section flex flex-column'>
      <div className='container flex flex-row'>
        <div className='header-box'>
          <h1>11,000+</h1>
          <h4>{scholarshipTitle}</h4>
        </div>
        <div className='header-box'>
          <h1>121M</h1>
          <h4>{usdYieldTitle}</h4>
        </div>
        <div className='header-box'>
          <h1>80,000+</h1>
          <h4>{discordTitle}</h4>
        </div>
        <div className='header-box'>
          <h1>6</h1>
          <h4>{gamesTitle}</h4>
        </div>
        <div className='header-box'>
          <h1>45+</h1>
          <h4>{gamesFiTitle}</h4>
        </div>
      </div>
    </div>
  );
}

export { HeaderMainSection };
