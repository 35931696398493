import React, { useContext, useEffect, useCallback } from "react";
import { StoreContext } from "index";
import { useLocation } from "react-router-dom";
import { ROUTES, RenderRoutes } from "./navigation/app";
import { Scene } from "screens";
import "assets/scss/main.scss";
import Web3 from "web3";
import { observer } from "mobx-react-lite";
import storeRedux from "./redux/store";
import { Provider } from "react-redux";
import { Web3ContextProvider } from "providers/Web3ContextProvider";

declare global {
  interface Window {
    ethereum: any;
    web3: any;
  }
}

const App = observer(() => {
  const location = useLocation();

  // const store = useContext(StoreContext);
  // const history = useHistory();

  // const loadWeb3 = useCallback(async () => {
  //   if (window.ethereum) {
  //     window.web3 = new Web3(window.ethereum);
  //   } else if (window.web3) {
  //     window.web3 = new Web3(window.web3.currentProvider);
  //   }
  // }, []);

  // useEffect(() => {
  //   loadWeb3();
  // }, [loadWeb3]);

  // const fetchAccounts = useCallback(async () => {
  //   if (window.ethereum) {
  //     try {
  //       const accounts = await window.ethereum.request({
  //         method: "eth_accounts",
  //       });
  //       store?.appStore.setAccounts(accounts);
  //       store?.appStore.setShowSidebar(false);
  //       // console.log("Current accounts set");
  //       // console.log("Checking if redirect to dashboard");
  //       // console.log(accounts);
  //       // if (accounts && accounts.length > 0) {
  //       //   history.push("/app");
  //       // }
  //     } catch (e) {
  //       console.log(e);
  //     }
  //   }
  // }, [store?.appStore]);

  // useEffect(() => {
  //   fetchAccounts();
  // }, [fetchAccounts]);

  // useEffect(() => {
  //   if (window.ethereum) {
  //     window.ethereum.on("accountsChanged", (accounts: any[]) => {
  //       store?.appStore.setAccounts(accounts);
  //     });
  //   }
  //   return () =>
  //     window.ethereum.removeListener("accountsChanged", () =>
  //       console.log("listener removed")
  //     );
  // }, [store?.appStore, history]);

  // useEffect(() => {
  //   const accounts = store?.appStore.getAccounts();
  //   console.log("Checking if redirect to dashboard");
  //   console.log(accounts);
  //   if (accounts && accounts.length > 0) {
  //     history.push('/app');
  //   }
  // }, [store?.appStore, history]);

  return (
    <div
      className={
        location.pathname.includes("/games") ? "App App--games" : "App"
      }
    >
      <Provider store={storeRedux}>
        <Web3ContextProvider>
          <Scene>
            <RenderRoutes routes={ROUTES} />
          </Scene>
        </Web3ContextProvider>
      </Provider>
    </div>
  );
});

export default App;
