import { useContext, useState } from "react";
import { StoreContext } from "index";
import DropdownIcon from "assets/images/icons/dropdown.svg";
import DropdownUpIcon from "assets/images/icons/dropdown-up.svg";
import ExternalLinkIcon from "assets/images/icons/external-link.svg";
import { headerItems, DropdownItem, AccountDisplay } from "components";
import { useWeb3Context } from "../providers/Web3ContextProvider";

type NavbarItemProps = {
  link: string;
  itemName: string;
  isCurrentlySelected?: boolean;
};

const sidebarFontSize = 18;
const linkToWhitePaper = "https://whitepaper.avocadodao.io/";

function NavbarItem({ link, itemName }: NavbarItemProps) {
  const dependence = ["Staking", "Vesting"];
  if (dependence.includes(itemName)) {
    return (
      <div className="flex flex-column" style={{ cursor: "pointer" }}>
        <a href={link} target="_blank" rel="noopener noreferrer">
          <div className="flex align-center full-sidebar-item">
            <p className="headline" style={{ fontSize: sidebarFontSize }}>
              {itemName}
            </p>
            <img
              src={ExternalLinkIcon}
              alt="External link"
              className="link-icon"
            />
          </div>
        </a>
      </div>
    );
  }

  return (
    <a
      href={link}
      target="_blank"
      rel="noreferrer noopener"
      style={{ textDecoration: "none" }}
      className="flex align-center full-sidebar-item"
    >
      <p className="headline" style={{ fontSize: sidebarFontSize }}>
        {itemName}
      </p>
    </a>
  );
}

type NavbarAccordionProps = {
  title: string;
  items: DropdownItem[];
};

function NavbarAccordion({ title, items }: NavbarAccordionProps) {
  const [isExpanded, setIsExpanded] = useState(false);
  return (
    <div className="expandable">
      <button
        onClick={() => setIsExpanded(!isExpanded)}
        className="flex align-center btn-text"
        style={{ textTransform: "none", padding: 0 }}
      >
        <p className="headline" style={{ fontSize: sidebarFontSize }}>
          {title}
        </p>
        {isExpanded ? (
          <img src={DropdownUpIcon} alt="Collapse" className="accordion-icon" />
        ) : (
          <img src={DropdownIcon} alt="Expand" className="accordion-icon" />
        )}
      </button>
      <div
        className="expanded-contents"
        style={isExpanded ? { height: items.length * 70 + "px" } : {}}
      >
        <ul>
          {items.map((item) => (
            <li>
              <a
                href={item.href}
                target="_blank"
                rel="noreferrer noopener"
                style={{ textDecoration: "none" }}
                className="flex align-center"
              >
                <img src={item.icon} alt={item.title} className="link-icon" />
                <p className="headline" style={{ fontSize: sidebarFontSize }}>
                  {item.title}
                </p>
              </a>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

type FullSidebarProps = {
  isOpen?: boolean;
  onPressConnect?: () => void;
};

function FullSidebar({
  isOpen = false,
  onPressConnect = () => { },
}: FullSidebarProps) {
  // const store = useContext(StoreContext);
  const { address, signer } = useWeb3Context();

  return (
    <div
      className={`flex flex-column full-sidebar${isOpen ? " full-sidebar-active" : ""
        }`}
    >
      {!signer ? (
        <>
          <button
            className="btn-hero btn-connect btn-header"
            onClick={onPressConnect}
          >
            CONNECT WALLET
          </button>
          <a href="https://quests.avocadodao.io/join" target="_blank">
            <button className="btn-hero btn-connect btn-header">JOIN</button>
          </a>
        </>
      ) : (
        <AccountDisplay connectedAccount={address} />
      )}
      <ul className="nav-list">
        <li>
          <NavbarItem link="https://quests.avocadodao.io/avocadude/" itemName="AVOLUTION" />
        </li>
        <li>
          <NavbarAccordion title="NEWS" items={headerItems.news} />
        </li>
        <li>
          <NavbarItem link={linkToWhitePaper} itemName="WHITEPAPER" />
        </li>
        <li>
          <NavbarItem link="https://core.avocadodao.io/games/" itemName="GAMES" />
        </li>
        <li>
          <NavbarItem link="/bridge" itemName="BRIDGE" />
        </li>
        <li>
          <NavbarItem link="https://staking.avocadodao.io" itemName="STAKING" />
        </li>
        <li>
          <NavbarItem link="https://rewards.avocadodao.io" itemName="VESTING" />
        </li>
        <li>
          <NavbarAccordion title="SOCIAL" items={headerItems.social} />
        </li>
        <li style={{ marginBottom: 50 }}>
          <NavbarAccordion title="BUY AVG" items={headerItems.token} />
        </li>
      </ul>
    </div>
  );
}

export { FullSidebar };
